export const paystackTransactionStatusEnum = [
    'Success',
    'Pending',
    'Cancelled',
    'Failed',
    'Not_Processed',
    'Invalid_Merchant',
    'Inactive_Merchant',
    'Invalid_OrderID',
    'Duplicate_OrderID',
    'Invalid_Amount',
    'Processing',
    'SuccessPendingFulfulment'
]